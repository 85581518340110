// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-graphql-training-frontend-course-js": () => import("/home/benjie/Dev/graphql-training/graphql-training.github.io/src/pages/graphql-training-frontend-course.js" /* webpackChunkName: "component---src-pages-graphql-training-frontend-course-js" */),
  "component---src-pages-graphql-training-fullstack-course-js": () => import("/home/benjie/Dev/graphql-training/graphql-training.github.io/src/pages/graphql-training-fullstack-course.js" /* webpackChunkName: "component---src-pages-graphql-training-fullstack-course-js" */),
  "component---src-pages-index-js": () => import("/home/benjie/Dev/graphql-training/graphql-training.github.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("/home/benjie/Dev/graphql-training/graphql-training.github.io/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/benjie/Dev/graphql-training/graphql-training.github.io/.cache/data.json")

